var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function formatDataEditUser(data) {
    var _a;
    if (!data.permissionGroup) {
        return data;
    }
    var subPermissionsGroupPermission = (_a = data.permissionGroup) === null || _a === void 0 ? void 0 : _a.map(function (group) { var _a; return (typeof group !== 'string' ? (_a = group.permissions) !== null && _a !== void 0 ? _a : [] : []); }).flat();
    var extraPermissions = data.extraPermissions;
    if (extraPermissions) {
        extraPermissions = extraPermissions.filter(function (elt) {
            if (typeof elt === 'string')
                return false;
            return !subPermissionsGroupPermission.some(function (subPerm) { return subPerm._id === elt._id; });
        });
    }
    data.permissionGroup = data.permissionGroup.map(function (elt) {
        return typeof elt === 'string' ? elt : elt._id;
    });
    data.extraPermissions =
        extraPermissions && extraPermissions.length > 0
            ? extraPermissions.map(function (elt) { return (typeof elt === 'string' ? elt : elt._id); })
            : undefined;
    var countMap = {};
    var uniqueArray;
    if (data.extraPermissions) {
        data.extraPermissions.forEach(function (str) {
            if (typeof str === 'string')
                countMap[str] = (countMap[str] || 0) + 1;
        });
        uniqueArray = data.extraPermissions.filter(function (str) { return countMap[str] === 1; });
    }
    return __assign(__assign({}, data), { extraPermissions: uniqueArray });
}
